<script setup lang="ts">
import { reactiveComputed, useVModels } from '@vueuse/core'
import { useRentalTime } from '~/composables/useRentalTime'
import CustomCalendar from '~/components/custom-calendar/index'
import { vAntdInputClosable } from '~/directives'
import { imtoken } from '~/utils/imtoken'
const props = withDefaults(defineProps<{
  rentDuration: number
  rentTimeSecond: number
  mode?: string
  readonly?: boolean
  rentResValue?: number
}>(), {
  rentDuration: 1,
  rentTimeSecond: 0,
  mode: 'b2c',
  readonly: false,
  rentResValue: 0,
})

const emit = defineEmits(['onChange', 'update:rentDuration', 'update:rentTimeSecond'])

const { rentDuration, rentTimeSecond } = useVModels(props, emit)

const { shortcutTime } = useRentalTime()

// 快捷选项
const shortcutButton = reactiveComputed(() => {
  const arr = [
    {
      dur: 1,
      unit: 'h',
      label: $t('qscwa0AArZFkKuiKRR4Gi'),
      value: 3600,
    },
    {
      dur: 3,
      unit: 'h',
      label: $t('4cPcjYm3cBE_F5c5zbBZ4'),
      value: 10800,
    },
    {
      dur: 1,
      unit: 'd',
      label: $t('Mb92l5z1dOowLmx_L0s-C'),
      value: 86400,
    },
    {
      dur: 3,
      unit: 'd',
      label: $t('C6ar9oFN8Y6aQ2brz15vs'),
      value: 259200,
    },
  ]
  return arr
})

const smallAmount = computed(()=> props.mode === 'b2c'  && props.rentResValue <= 100000 && imtoken.isImToken())

function chooseRentDuration(item: number) {
  const data = shortcutTime?.find((i: any) => i.value === item)
  if (!data)
    return
  rentTimeSecond.value = item
  rentDuration.value = data.dur
  emit('onChange', { dur: data.dur, unit: data.unit })
}

function onIinputChange(e: number) {
  const data = shortcutTime.find((i: any) => i.dur === e && i.unit === 'd')
  if (!data)
    return
  rentTimeSecond.value = data.value
  rentDuration.value = data.dur
  emit('onChange', { dur: data.dur, unit: data.unit })
}
</script>

<template>
  <div>
    <AInputNumber
      v-model:value="rentDuration"
      v-antd-input-closable
      class="ant-cover__input-number-hidden-addon-after w-[100%]"
      :readonly="props.readonly"
      :controls="false"
      autocomplete="off"
      step="1"
      :max="30"
      :placeholder="$t('dHgidzfd5qiruU6fTpsQ')"
      @change="onIinputChange"
    >
      <template #prefix>
        <i class="i-material-symbols:timer-outline-rounded text-[1.2em] text-[#266ef1]" />
      </template>
      <template #addonAfter>
        <div class="flex justify-center items-center text-primary">
          {{ (rentTimeSecond ?? 0) >= 86400 ? $t('pTlUvYtDyBk3J0M6SwH8') : $t('e5EcgLaO2CnOhsQrV3zNt') }}
        </div>
      </template>
    </AInputNumber>
    <div class="energy-select" :class="{'imtoken': smallAmount }">
      <div
        v-for="(i, k) of shortcutButton"
        :key="k"
        class="energy-info"
        @click="chooseRentDuration(i.value)"
        :data-test-id="`33rn${k}`"
        :class="{'!hidden': smallAmount && i.unit === 'd'}"
      >
        <span>{{ i.label }}</span>
      </div>
      <CustomCalendar
        :rentTimeSecond="rentTimeSecond"
        class="energy-info"
        :mode="props.mode"
        @onClick="chooseRentDuration"
        data-test-id="pptj"
        :class="{'!hidden':  smallAmount}"
      >
        <div>
          <span>{{ $t('nSxV7V2IDyUmbIgO8bCcQ') }}</span>
        </div>
      </CustomCalendar>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.energy-select){
  --uno: 'mt-9px text-[14px] flex text-primary gap-8px lt-mobilel:text-[12px] lt-mobilel:grow-1 lt-mobilel:gap-6px';

  .energy-info {
    --uno: 'w-90px h-34px flex-center cursor-pointer border-solid border-1.5 border-rd-8px hover:border-active hover:text-active ';
    transition: display 0.3s;
    border-color: rgb(128 152 191 / 50%);

    @media bp-lt-mobile {
      --uno: 'h-28px w-[100%]';
    }
  }
}
:deep(.energy-select.imtoken){
  --uno: 'justify-start';
  .energy-info{
    width: 50%;
  }
}
</style>
