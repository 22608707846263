import { reactiveComputed } from '@vueuse/core/index'
import { getPayAmountByResource } from '~/composables/app/getPayAmountByResource'
import BigNumber, { default as bignumber } from 'bignumber.js';

interface CalculationParams {
  resourceValue: number
  rentDuration: number
  rentTimeUnit: string
}

interface CalculationResult {
  freezeTRX: string;
  amount: BigNumber;
  serviceAmount: BigNumber;
  tSun: BigNumber;
  saveTRX: BigNumber;
  discount: BigNumber;
  originalPrice: BigNumber;
}

export function resourcePriceRange(autoMarker: Ref<boolean>): { minRentable: number;maxRentable: number } {
  return reactiveComputed(() => {
    const { config } = useAppState()
    const range = {
      minRentable: config.value?.order?.energy_min_value ?? 1_000_000,
      maxRentable: config.value?.order?.energy_max_value ?? 1_000_000,
    }
    if (autoMarker.value)
      range.maxRentable = config.value?.order?.energy_c2c_max_value ?? 1_000_000
    else
      range.maxRentable = config.value?.order?.energy_max_value ?? 1_000_000
    
    
    return range
  })
}

export function useCalculation(params: CalculationParams): CalculationResult {
  const { account } = tronComp.useAccountCurrent()
  
  const calculationData = reactive<CalculationResult>({
    freezeTRX: '0.00', // 冻结能量
    amount: bignumber(0), // 计算结果
    serviceAmount: bignumber(0), // 手续费
    tSun: bignumber(0), // 计算单价
    saveTRX: bignumber(0), // 烧毁能量
    discount: bignumber(0), // 节省 %
    originalPrice: bignumber(0),
  });
  
  if (
    !params.resourceValue
    || params.resourceValue === 0
    || params.rentDuration === 0
  )
    return calculationData;

  const { energyUnitPrice } = account.resource
  calculationData.freezeTRX = !energyUnitPrice
    ? '0.00'
    : (params.resourceValue / energyUnitPrice).toFixed(2)
  
  const localComputing = getPayAmountByResource(params);
  
  calculationData.amount = localComputing.payAmount ?? bignumber(0);
  calculationData.serviceAmount = localComputing.serviceAmount ?? bignumber(0);
  calculationData.tSun = localComputing.sun ?? bignumber(0);
  
  const needBurningEnergy = bignumber(params.resourceValue).multipliedBy(420).dividedBy(1000000);
  const duration = params.rentTimeUnit === 'd' ? bignumber(params.rentDuration) : bignumber(1);
  
  //  折扣
  calculationData.saveTRX = needBurningEnergy.multipliedBy(duration).minus(calculationData.amount);
  
  //  原价
  calculationData.originalPrice = needBurningEnergy.multipliedBy(duration);
  
  const calcDiscount = bignumber(100).multipliedBy(
    bignumber(1).minus(calculationData.amount.dividedBy(needBurningEnergy.multipliedBy(duration)))
  );
  calculationData.discount = calcDiscount.integerValue(BigNumber.ROUND_CEIL);
  
  return calculationData;

}
